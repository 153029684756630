export const SupporterVoiceList = [
  {
    spec: '40代 女性 (フレンチブルドッグ)',
    image: 'supporter01.webp',
    text: 'ペットを飼っている人たちには夢のようなマンションだと思います。このようなマンションが沢山できることを願っております。',
  },
  {
    spec: '40代男性（ラブラドールレトリバー）',
    image: 'supporter02.webp',
    text: '大型犬を飼っていますが、大型犬が飼えるマンションの引越し先が無くて困っております。大型犬を飼えるマンション増えると非常に助かります。',
  },
  {
    spec: '30代 ⼥性 (プードル)',
    image: 'supporter03.webp',
    text: 'ペットを飼育する上で、たくさんの悩みごとがありますが、少しでも悩みが解消するマンションがあればと思っていました。是非、実現してほしいです。',
  },
  {
    spec: '40代 男性 (フレンチブルドッグ)',
    image: 'supporter04.webp',
    text: '愛犬家にとって理想のマンションができるのであれば、是非応援させていただきます。',
  },
  {
    spec: '30代 ⼥性 (ゴールデンレトリバー)',
    image: 'supporter05.webp',
    text: '近所にペットホテルも無く、シッターさんが常駐しているマンションであれば、気兼ねなく外出できると思います。いち早くマンションを作ってほしいです。',
  },
  {
    spec: '30代 ⼥性 (ダックスフンド)',
    image: 'supporter06.webp',
    text: 'マンションに⽝の保育園があれば、昼間仕事に⾏っている間に、しつけを覚えることができて、お迎えも必要なし。すごく助かります！！',
  },
  {
    spec: '30代 ⼥性 (ラグドール)',
    image: 'supporter07.webp',
    text: 'ペットを⼀⼈にさせることが多いのでペットシッターさんがついているマンションであれば、安⼼して、お留守番をさせることができる理想のマンションだと思います。',
  },
  {
    spec: '20代 女性（ミヌエット）',
    image: 'supporter011.webp',
    text: '猫を飼っており散歩等で外出しないため猫好きコミュニティーが広がらず、猫好き同士で情報交換と思っていました。猫好きの方と交流ができたらと思っています！',
  },
  {
    spec: '30代 女性（ミックス）',
    image: 'supporter08.webp',
    text: 'ペットが健康に楽しく暮らしてくれることが自分の幸せに直結するので、ペットファーストで考えられたマンションに住めたら最高です！',
  },
  {
    spec: '30代 ⼥性 (ミニチュアダックスフンド)',
    image: 'supporter010.webp',
    text: '夫婦共に外出が多くお散歩も不足がちなのが気になっています。マンション内で預けられて何かの時にも頼れる人がいる環境はとてもありがたいです！',
  },
  {
    spec: '30代 女性（ミックス）',
    image: 'supporter013.png',
    text: '「P-man」は猫の健康と幸せを第一に考える私にとってぴったりのお家です。キャットウォークなど設備が整ったマンションがあると嬉しいですね。',
  },
  {
    spec: '20代 男性 (ミヌエット)',
    image: 'supporter012.webp',
    text: '持ち家で犬猫を飼ったことがありますが、賃貸マンションでペットを飼う決意できなかった過去があります。是非とも、この構想を実現してもらいたいと思います。',
  },
  {
    spec: '40代 女性 (マルプー)',
    image: 'supporter014.png',
    text: '仕事や外出でペットだけで留守番が必要な時に、常駐のペットシッターや保育園があったらとても心強いです。人もペットも安心して住める環境が理想です。ぜひ実現して欲しいと思います。',
  },
];

export const SupporterCommennt = [
  '「ペットのコンシェルジュ」という\n発想が今までになく画期的！',
  '”ペット可”に留まらない\n”ペットファースト”という\nペット想いなのが嬉しい。',
  '緊急時でもペットを預けたり相談できる\n頼れる人がすぐ近くにいると思うと、\n安心できる',
  '高齢になっていく愛犬とも一緒に、\nずっと無理なく暮らせると思うと嬉しい。\n周りの理解やサポートがある環境って大事。',
  'まさにあったらいいなと\n思っていた住宅',
  '猫にとっての居心地の良さを\n第一に考えたい。そんな住まいが\n欲しいと思ってた。夢が叶う！',
  '仕事や予定も変に制約せず過ごしたい。\n同じように困っている方がいたら\n助けたい、という思いまでも\n叶えることができるって最高！',
  'ペットと人の生活の悩みに、\n不動産会社という立場から丸ごと\n挑戦しようとする姿勢に興味津々。',
  '人と犬の両方の幸せを感じる\nプロジェクトに共感\n一緒にあったらいいな！を考えたい',
];

export const Reports = [
  {
    q: '外出時など、ペットシッターや犬の保育園はご利用されていますか？',
    a: '「良いペットシッターさんに出会えたので助かっています」\n「家から遠いけれど、安心して任せられる保育園を利用しています。信頼が第一！」\n「家族やご近所さんに協力してもらっています」\n「保育園を利用するときは、お預け・引き取りの時間に追われて、いつもバタバタしています。」\n「出社するとき、急遽遅くなったり、出張で日を跨いだりしたときに柔軟に預けられる人や場所が欲しいです」\n「いきなり海外出張が入ることもあります。ペットシッターも預かりも事前予約が基本なので、\n複数預け先を持っておく必要があるなと思っています」\n「自分が風邪や病気になったとき、ペットのために駆けつけてくれる存在が欲しいです」',
    comment:
      '特に働いている方だと、仕事で急用が発生することもしばしば。\n大切なペットのために、皆さん色々な工夫と時間を注いでらっしゃいました。\n我が子同様、ペットが安心して過ごせるようにしたいですし、気兼ねなく預けられる存在がいると心強いですよね。\n信頼できるペットシッターさんや保育園を見つけるまでのお話なども、とても参考になりました！',
    image: 'friend02.png',
    imageWidth: 171,
  },
  {
    q: 'ペットシッターや犬の保育園について、こうだったら良いなと思うことはありますか？',
    a: '「ペットシッターに興味があるのですが、知らない人を留守宅に入れるのにどうしても抵抗があって、チャレンジできていないです」\n「慣れないペットホテルに預けたことで、ストレスが溜まって病院通いになってしまったことも・・・」\n「家族や友達、ご近所さんにお願いできていてもあまり頻繁になってくると、やはり気を使ってしまいます。」\n「自宅の近くで、送り迎えが楽だと助かります。」\n「ペットと向き合ってそれぞれの個性を見てくれていると思えると、安心します。」',
    comment:
      'どの保育園や病院、ホテル、ペットシッターが、うちの子に合っているのか、わからないし不安になりますよね。\nご家族や友人、近所のペット友達に恵まれている場合も、頻度や時間が増えると申し訳なさを感じてしまうこともあるようです。\nマンションの1階に常駐ペットシッター・保育園があれば、様子もわかりやすいし、移動の手間もなくなるし、\nやっぱり良いなと改めて思わせていただきました！',
    image: 'friend03.png',
    imageWidth: 113,
  },
  {
    q: 'ペットオーナー同士のコミュニケーションについては、いかがですか？',
    a: '「お散歩をしない猫は知り合う機会がないので、情報交換がネックです。リアルで会えると安心感がありますね」\n「同じ建物に住んでいる人（自分の生活環境に近い人）に話を聞いてみたいです」\n「ドッグランでペットコミュニティができて、毎日の散歩が楽しみになっています」\n「今は隣に住んでいる人でさえ顔も知らないので、鳴き声や足音など変に気を遣ってしまうのですが、\nP-manではペットで繋がれることで安心感が生まれそうです」\n「P-manの気軽に寄れるペット可なコミュニティスペースは面白いですね。イベントもあれば参加したいです」\n「コミュニティスペースは気が向いたときに立ち寄れたら良いですね。外部利用もOKにする開放DAYがあってもいいのでは」',
    comment:
      '皆さん、ペットのための情報交換ができるリアルなコミュケーションを大切にしていらっしゃるようです。\nペットの楽しみのためとお互いのペットを愛であう喜びはもちろん、相談や情報交換、\n助け合いという意味でもポジティブな反応が多かったです。\n住まい＝プライベートでもあり、「距離感」は程よく保ちたいというご意見もありました。',
    image: 'friend01.png',
    imageWidth: 242,
  },
  {
    q: 'P-manがあったら・・・どんな生活がしたい/どうなると思いますか？',
    a: '「もっと旅行にも行きたいです」\n「今は在宅勤務があるので保育園利用は月2～3回で済ませていますが、実際はもっと出社したいです。P-manで保育園が利用しやすくなったら、もっとフレキシブルに出社や外出ができるようになりそうです！」\n「ペット預かりの利用は、今は月3−4回くらいですが、突発的に利用できるならもっと使うと思います」\n「ペットの老後の不安も軽減されると思います」\n「マンション内でたくさんのペットに会えるだけで楽しくなるし、挨拶もしやすくなるので、気持ちも明るくなりそうです。」\n「近隣迷惑の不安が減って、お互い様の気持ちで穏やかな気持ちで暮らせそうです。」\n「ペットにお留守番してもらう時のなんとも言えない後ろめたさが軽減できるので、帰宅するのも一層楽しみになりそうです」',
    comment:
      '今もペットと充実した生活を送っていらっしゃる皆さんですが、ペットのためにあえて控えていることがあることを教えていただきました。\nP-manがペット達とペットオーナーの皆さんに、もっと笑顔と豊かな時間が増えるお手伝いができる存在になっていきたいと思います！',
    image: 'friend04.png',
    imageWidth: 224,
  },
];
