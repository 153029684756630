import React, { useState, useEffect, useRef } from 'react';
/** components **/
import { Layout, FadeUp, ParallaxItem } from 'components/organisms';
import { ContentsTitle } from 'components/molecules';
import { SERVICE_NAME, REGISTER_FORM_URL } from '../const';
import { SupporterCommennt, Reports } from 'lib/supporter-voice';

import classNames from 'classnames';
import SwiperCore from 'swiper';
import 'swiper/scss';
import { Swiper, SwiperSlide } from 'swiper/react';

/** styles **/
import s from '../styles/pages/interview.module.scss';
import Pwan from 'assets/images/pwan.png';
import BgLogo from 'assets/images/bg-logo.svg';
import TopHumanImg from 'assets/images/top-human.svg';
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';
import { ReactComponent as BlackCircleArrow } from 'assets/images/black-circle-arrow.svg';

export const Interview: React.FC = () => {
  const [swiperIsBeginning, setSwiperIsBeginning] = useState<boolean>(true);
  const [swiperIsEnd, setSwiperIsEnd] = useState<boolean>(false);

  const swiperRef = useRef<SwiperCore>();
  const SwiperButtonPrev = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slidePrev()} disabled={swiperIsBeginning}>
        <BlackCircleArrow className={classNames(s.navButton, s.prevButton)} />
      </button>
    );
  };

  const SwiperButtonNext = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slideNext()} disabled={swiperIsEnd}>
        <BlackCircleArrow className={classNames(s.navButton, s.nextButton)} />
      </button>
    );
  };
  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/worries',
      });
    }
  }, []);

  return (
    <Layout>
      <div className={s.interviewPageWrapper}>
        <FadeUp delay={0}>
          <ContentsTitle
            className={s.contentsTitle}
            title="REPORT"
            subTitle="サポーターコメント＆インタビュー　レポート"
          />
        </FadeUp>
        <FadeUp delay={0}>
          <div className={s.lead}>
            <ParallaxItem className={s.parallaxBg}>
              <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
            </ParallaxItem>
            <div className={s.inner}>
              <p>
                P-manサポーターの皆様、ご登録いただき誠にありがとうございます！おかげさまで、2,700名※を超えるサポーター様にご登録をいただきました！11月以降も続々増えております！
                <br />
                <br />
                また数名の方にはインタビューにご協力いただきました。お忙しい中、貴重なお時間をありがとうございました！
                <br />
                <br />
                ご登録時のコメント及びインタビューの中で、応援・共感のメッセージ、
                <br />
                そして参考になる刺激的なご意見をたくさんいただきました。
                <br />
                本当はすべてご紹介したいところではありますが、今回はその中の一部だけ、
                <br />
                いただいたコメントとインタビュー内容をご紹介させていただきます！
              </p>
              <span>※2024年11月1日時点</span>
              <div className={s.notice}>
                <p>サポーターはまだまだ募集中！</p>
                <img src={Pwan} alt="ピーワン" />
              </div>
            </div>
          </div>
          <div className={s.supportersContents}>
            <ContentsTitle className={s.contentsTitle} title="SUPPORTER’S VOICE" subTitle="サポーターの皆様の声" />
            <div className={s.spOnly}>
              <Swiper
                className={s.slideWrapper}
                onBeforeInit={(swiper: any) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(swiper: any) => {
                  setSwiperIsBeginning(swiper.isBeginning);
                  setSwiperIsEnd(swiper.isEnd);
                }}
                spaceBetween={24}
                speed={700}
                slidesPerView={1}
                breakpoints={{
                  1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                  },
                }}
              >
                <SwiperSlide className={s.item}>
                  <div className={s.text}>{SupporterCommennt[0]}</div>
                  <div className={s.text}>{SupporterCommennt[1]}</div>
                  <div className={s.text}>{SupporterCommennt[2]}</div>
                </SwiperSlide>
                <SwiperSlide className={s.item}>
                  <div className={s.text}>{SupporterCommennt[3]}</div>
                  <div className={s.text}>{SupporterCommennt[4]}</div>
                  <div className={s.text}>{SupporterCommennt[5]}</div>
                </SwiperSlide>
                <SwiperSlide className={s.item}>
                  <div className={s.text}>{SupporterCommennt[6]}</div>
                  <div className={s.text}>{SupporterCommennt[7]}</div>
                  <div className={s.text}>{SupporterCommennt[8]}</div>
                </SwiperSlide>
              </Swiper>
              <div className={s.navWrapper}>
                <SwiperButtonPrev />
                <SwiperButtonNext />
              </div>
            </div>
            <ul className={s.pcOnly}>
              {SupporterCommennt.map((comment, index) => (
                <li key={index}>{comment}</li>
              ))}
            </ul>
          </div>
          <div className={s.concierge}>
            <img className={s.topHuman} src={TopHumanImg} alt={SERVICE_NAME} width={219} height={272} />
            <div className={s.text}>
              <p>
                犬さん、猫さん、うさぎさん、鳥さん、エキゾチックアニマルさん
                <br />
                様々なペットオーナーの皆さんからご登録＆コメントをいただきました！
              </p>
              <span>P-manメンバーから心より感謝申し上げます。</span>
            </div>
          </div>
          <div className={s.reportContents}>
            <ParallaxItem className={s.parallaxBg}>
              <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
            </ParallaxItem>
            <ContentsTitle
              className={s.contentsTitle}
              title="INTERVIEW REPORT"
              subTitle="サポータインタビューレポート"
            />

            <p className={s.introText}>
              サポーターにご登録いただいた方の中から数名の方にインタビューをさせていただきました。
              <br />
              頂いたご意見の一部を簡単ですがご紹介いたします。
            </p>
            <div>
              {Reports.map((report, index) => (
                <div className={s.report} key={index}>
                  <p className={s.q}>{report.q}</p>
                  <p className={s.a}>{report.a}</p>
                  <p className={s.comment}>
                    （P-manスタッフより）
                    <br />
                    {report.comment}
                    <img
                      src={require(`assets/images/${report.image}`)}
                      width={report.imageWidth}
                      alt="INTERVIEW REPORT"
                    />
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={s.memberCommentContents}>
            <ContentsTitle className={s.contentsTitle} title="SPECIAL THANKS！" subTitle="事業開発リーダーより" />
            <div className={s.memberComment}>
              <div className={s.imageWrapper}>
                <img src={require(`assets/images/member01.webp`)} alt="大森 真利子" />
                <div>
                  <p className={s.position}>
                    三菱地所住宅業務企画部 <br />
                    新事業創造部
                  </p>
                  <p className={s.name}>大森 真利子</p>
                </div>
              </div>
              <p>
                サポーター登録いただいた皆様、またインタビューにご協力いただいた皆様
                <br className={s.pcOnly} />
                本当にありがとうございました！
                <br />
                ご登録いただいた内容・コメントを全て拝読させていただきました。
                <br className={s.pcOnly} />
                どのコメントも、ペット愛・動物愛、飼い主同士の気持ちに寄り添った温かい思いに
                <br className={s.pcOnly} />
                溢れるものばかりで何度も心を動かされました。
                <br />
                ペットの多様性、お部屋の広さや価格・設備、住みたい地域や災害対策などなど
                <br className={s.pcOnly} />
                真摯なご意見もたくさんいただきとても参考になりました。
                <br />
                <br />
                皆さんのP-manへの共感と期待を力に事業化に向けて尽力してまいります！
                <br />
                <br />
                引き続き皆様のご支援・ご協力を賜れますと幸いです。どうぞよろしくお願いいたします！
              </p>
            </div>
          </div>
        </FadeUp>
      </div>

      <div className={s.leadContents}>
        <p>
          あなたも「P-man」サポーターになって、 <br className={s.pcOnly} />
          理想のペット共生型マンションを一緒に実現させませんか？
        </p>
        <a className={s.linkToForm} href={REGISTER_FORM_URL} target="_blank" rel="noreferrer">
          サポーター登録（無料）
          <GreenCircleArrow className={s.arrowIcon} />
        </a>
      </div>
    </Layout>
  );
};
