import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
/** lib **/
import { useScrollToAnchor } from 'lib/use-scroll-anker-link';
/** components **/
import {
  KeyVisual,
  WhatIsPman,
  IssuesInPetOwnership,
  PossibleItems,
  OurFutures,
  Friends,
  Comics,
  Worries,
  Equipment,
  AreaFloorPlanRent,
  Concept,
  Member,
  OurPartner,
  SupporterVoice,
  Faq,
  Layout,
} from 'components/organisms';
/** styles **/
import s from '../styles/pages/top.module.scss';
import Pwan from 'assets/images/pwan.png';

export const Top: React.FC = () => {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animationEl = [inViewRef1];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.inview);
          } else {
            entry.target.classList.remove(s.inview);
          }
        });
      },
      {
        root: null,
        rootMargin: '-100% 0px 0px 0px',
        threshold: 0,
      },
    );
    animationEl.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      animationEl.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [animationEl]);

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/',
      });
    }
  }, []);

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  return (
    <Layout>
      <div id="top" className={s.topWrapper}>
        <div className={s.kv} ref={inViewRef1}>
          <KeyVisual />
          <Link className={s.notice} to="/interview">
            <p>サポーターの皆さまからの声をまとめました</p>
            <img src={Pwan} alt="ピーワン" />
          </Link>
        </div>
        <WhatIsPman />
        <IssuesInPetOwnership />
        <PossibleItems />
        <OurFutures />
        <Comics />
        <Worries />
        <Equipment />
        <AreaFloorPlanRent />
        <Concept />
        <Member />
        <Friends />
        <OurPartner />
        <SupporterVoice />
        <Faq />
      </div>
    </Layout>
  );
};
