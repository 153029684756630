import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

/* pages */
import { Top, Worries, Terms, Interview } from './pages';
import { ScrollTop } from 'lib/scrill-to-top';

import './assets/styles/base.scss';

function App() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/worries" element={<Worries />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/interview" element={<Interview />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
